import React from 'react'
import PropTypes from 'prop-types'
import HeaderArticle from '@/components/fragments/HeaderArticle'
import { useTranslation, Trans } from 'react-i18next'
import CardArticle from '../fragments/CardArticle'
import Footer from '@/components/Footer'
import { StyledContainer, StyledArticle } from './styles'

const AboutUs = (props) => {
  const { transparencyMenu } = props
  const { t } = useTranslation()

  return (
    <StyledContainer className='has-header'>
      <HeaderArticle>
        <i className="far fa-building" />
        <Trans i18nKey="ABOUT_US">
          Sobre a <span>Spartacus</span> Brazil
        </Trans>
        <span className='subTittle'>{t('Sobre Nós Descrição')}</span>
      </HeaderArticle>
      <StyledArticle>
        <CardArticle title={`${t('Nossos Objetivos')}`}>
          <Trans i18nKey="NOSSOS_OBJETIVOS_TEXT" components={{ p: <p />, 1: <b /> }} />
        </CardArticle>
  
        <CardArticle title={`${t('Nossos Valores')}`}>
          <h4>{t('Valores que preservamos e praticamos no Mercado Logístico')}</h4>
          <h3><i className="fas fa-rocket" />{t('MISSÃO')}:</h3>
          <p>{t('Fornecer soluções e serviços com segurança e excelência, por meio de pessoas qualificadas, customizando os custos de cada operação, agregando valor aos clientes e parceiros, visando a sustentabilidade do negócio')}.</p>
  
          <h3><i className="fas fa-eye" />{t('VISÃO')}:</h3>
          <p>{t('Ser a empresa líder em seu segmento, reconhecida por sua excelência, segurança, inovação e criação de valores')}.</p>
  
          <h3><i className="fas fa-seedling" />{t('VALORES')}:</h3>

          <Trans i18nKey="ETICA" components={{ p: <p />, 1: <b /> }} />
          <Trans i18nKey="RESULTADOS" components={{ p: <p />, 1: <b /> }} />
          <Trans i18nKey="RELACIONAMENTO" components={{ p: <p />, 1: <b /> }} />
          <Trans i18nKey="RESPEITO" components={{ p: <p />, 1: <b /> }} />
          <Trans i18nKey="SEGURANCA" components={{ p: <p />, 1: <b /> }} />
          <Trans i18nKey="SUPERACAO" components={{ p: <p />, 1: <b /> }} />

          <h3><i className="fas fa-plus" />{t('E MAIS')}:</h3>
          <ul>
            <Trans i18nKey="E_MAIS_TEXT" components={{ li: <li />, 1: <b /> }} />
          </ul>
        </CardArticle>
      </StyledArticle>
  
      <Footer transparencyMenu={transparencyMenu} />
    </StyledContainer>
  )
}

AboutUs.propTypes = {
  transparencyMenu: PropTypes.bool.isRequired,
}

export default AboutUs
