const { useTranslation } = require("react-i18next");

const useTranslatedContactType = () => {
  const { t } = useTranslation();

  return [
    {
      value: t('Serviços'),
      label: t('Serviços'),
    }, {
      value: t('Administrativo'),
      label: t('Administrativo'),
    }, {
      value: t('Portal'),
      label: t('Portal'),
    }
  ]
}

export default useTranslatedContactType
