import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import LOGO from '@/imgs/logo-spartacus-home.png'
import useTranslatedServiceList from '@/TextsPagesMock/servicesList'
import { Link } from "react-router-dom"
import { useTranslation } from 'react-i18next'
import LogoBranco from '@/imgs/logomarca-branco.png'
import Footer from '@/components/Footer'
import {
  StyledContainer,
  StyledLogo,
  StyledBannerWhoWeAre,
  ServiceSection,
  StyledBoxes,
} from './styles'

const Home = (props) => {
  const { transparencyMenu } = props
  const [currentLogoIndex, setCurrentLogoIndex] = useState(0)

  const serviceList = useTranslatedServiceList()
  const { t } = useTranslation()

  const MSG_SHOW = [
    {
      title: t('MSG SHOW TITLE 1'),
      sub: t('MSG SHOW SUB 1')
    }, {
      title: t('MSG SHOW TITLE 2'),
      sub: t('MSG SHOW SUB 2')
    }
  ]

  useEffect(() => {
    const interval = setInterval(() => {
      const index = MSG_SHOW.length - 1

      setCurrentLogoIndex(currentLogoIndex => currentLogoIndex === index ? 0 : currentLogoIndex + 1)
    }, 7000)
    return () => clearInterval(interval)
  }, []);

  const renderService = (box, i) => {
    const renderIcon = () => {
      if (box.renderIcon) return (
        <>
          <i className={box.icon[0]} />
          <i className={box.icon[1]} />
        </>
      )

      return (
        <i className={box.icon} />
      )
    }

    return (
      <StyledBoxes key={i}>
        <div>
          {renderIcon()}
        </div>
        {box.title}
        <p>
          {box.text}
        </p>
        <a href={box.url} className="button"><i className="fas fa-plus" /></a>
      </StyledBoxes>
    )
  }

  return (
    <>
      <StyledContainer className='has-header'>
        <StyledLogo>
          <div>
            <img src={LOGO} alt="Logo Spartacus Brazil"  />
            <h2>{MSG_SHOW[currentLogoIndex].title}</h2>
            <h3>{MSG_SHOW[currentLogoIndex].sub}</h3>
          </div>
        </StyledLogo>

        <StyledBannerWhoWeAre>
          <h3>{t('HOME MSG 1')}</h3>
          <Link to="/quem-somos" className='button'>{t('Saber Mais')}</Link>
        </StyledBannerWhoWeAre>

        <ServiceSection>
          <h2>
            {t('Serviços Ativos')}
            <img src={LogoBranco} alt="logo spartacus" />
          </h2>
          <div className="containerBoxes">
            {serviceList.map((box, index) => renderService(box, index))}
          </div>
        </ServiceSection>

        <Footer transparencyMenu={transparencyMenu} />
      </StyledContainer>
    </>
  )
}

Home.propTypes = {
  transparencyMenu: PropTypes.bool.isRequired,
}

export default Home
