import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ContainerLanguageSelector } from './styles';

const LanguageSelector = (props) => {
  const { isOpened } = props;
  const { i18n } = useTranslation();

  const [language, setLanguage] = useState('');

  useEffect(() => {
    setLanguage(i18n.language);
  }, [i18n.language
  ]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setLanguage(lng);
  };

  return (
    <ContainerLanguageSelector className={`${isOpened ? 'mobile' : ''}`}>
      {language === 'pt' ? (
        <button className="language-bt" onClick={() => changeLanguage('en')}>
          <i className="fa-solid fa-language" /> EN
        </button>
      ) : (
        <button className="language-bt" onClick={() => changeLanguage('pt')}>
          <i className="fa-solid fa-language" /> PT
        </button>
      )}
    </ContainerLanguageSelector>
  );
};

LanguageSelector.propTypes = {
  isOpened: PropTypes.bool,
};

LanguageSelector.defaultProps = {
  isOpened: false,
};

export default LanguageSelector;
