import React from 'react'
import PropTypes from 'prop-types'
import { Field, CustomLoading } from './styles'
import { useTranslation } from 'react-i18next'

const InputText = (props) => {
  const {
    label,
    value,
    name,
    onChange,
    type,
    onBlur,
    isRequired,
    className,
    isLoading,
    isDisabled,
  } = props

  const { t } = useTranslation()
  return (
    <Field className={className}>
      <span>
        {Boolean(label) && label}
        {isRequired && (
          <i className="fas fa-asterisk" title={t('campo obrigatorio')}/>
        )}
      </span>
      <input
        name={name}
        value={value}
        onChange={onChange}
        type={type}
        onBlur={onBlur}
        required={isRequired}
        disabled={isDisabled || isLoading}
      />
      {isLoading && (
        <CustomLoading className="fas fa-spinner" />
      )}
    </Field>
  )
}

InputText.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  type: PropTypes.string,
  isRequired: PropTypes.bool,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
}

InputText.defaultProps = {
  type: 'text',
  onBlur: null,
  isRequired: false,
  className: '',
  label: '',
  isLoading: false,
  isDisabled: false,
}

export default InputText
