import React, { useState } from 'react'
import PropTypes from 'prop-types'
import emailjs from '@emailjs/browser'
import HeaderArticle from '../fragments/HeaderArticle'
import InputText from '../fragments/Forms/InputText'
import SelectText from '../fragments/Forms/SelectText'
import TextareaText from '../fragments/Forms/TextareaText'
import { formatterPhone } from '@/utils/formatter'
import useTranslatedContactType from '@/TextsPagesMock/selectOptions'
import { useTranslation, Trans } from 'react-i18next'
import Modal from '../fragments/Modal/Modal'
import Footer from '@/components/Footer'
import {
  ContactContainer,
  FormContact,
  SectionContainer,
} from './styles'

const defaultForm = {
  spartacus: 'Spartacus Brazil'
}

const Contact = (props) =>  {
  const { transparencyMenu } = props
  const [formValues, setFormValues] = useState(defaultForm)
  const [showModal, setShowModal] = useState(false)
  const [messageModal, setMessageModal] = useState(null)
  const { t } = useTranslation()
  const contactType = useTranslatedContactType()

  const emailType = {
    success: t('Email enviado'),
    error: t('O Email não foi enviado'),
    need_to_feel: t('Preencha todos os campos'),
  }


  const handleChange = (e) => {
    const { name, value} = e.target

    const newValue = {
      ...formValues,
      [name]: value,
    }

    setFormValues(newValue)
  }

  const setupModal = (bool) => {
    if (bool) {
      setFormValues(defaultForm)
    }
    setMessageModal(bool ? emailType.success : emailType.error)
    setShowModal(true)
  }

  const sendEmail = (e) => {
    e.preventDefault()

    if (!formValues.fullName || !formValues.subject || !formValues.phoneNumber || !formValues.userEmail || !formValues.message) {
      setMessageModal(emailType.need_to_feel)
      setShowModal(true)
      return
    }

    emailjs.send('service_svj3dgk', 'template_hz7df4a', formValues, 'user_lNh164x4Mlc6T4dVdOwva')
      .then(() => {
        setupModal(true)
      }, () => {
        setupModal(false)
      })
  }

  const onCloseModal = () => {
    setMessageModal(null)
    setShowModal(false)
  }

  const renderModal = () => (
    <Modal
      title={messageModal === emailType.success ? t('Sucesso!') : 'Ops!'}
      subTitle={messageModal}
      type={messageModal === emailType.success ? 'success' : 'error'}
      closeModal={onCloseModal}
    >
      <button
        className='button'
        onClick={() => onCloseModal()}
      >
        Ok
      </button>
    </Modal>
  )

  return (
    <div className='has-header'>
      {showModal && renderModal()}
      <HeaderArticle>
        <i className="fas fa-at" />
        <Trans i18nKey="CONTACT_1" components={{ 1: <span /> }} />
        <span className='subTittle'>{t('Temos uma equipe preparada para suas necessidades')}.</span>
      </HeaderArticle>

      <ContactContainer>
        <SectionContainer>
          <div className='left'>
            <h2><i className="far fa-address-card" />{t('ENTRE EM CONTATO')}</h2>
            <div className='contact-side'>
              <p>{t('CONTACT_2')}</p>
              <div>
                <span>
                  <a href="https://wa.me/5511945837127" rel="noreferrer" target="_blank"><i className="fab fa-whatsapp" /></a>
                  <a href="tel:+5511945837127"><i className="fas fa-mobile-alt" /></a>
                </span>
                <span>
                  <span>(11) 94583-7127</span>
                  <span>Etso J. Montagnoli</span>
                </span>
              </div>
              <div>
                <span>
                  <a href="https://wa.me/551139380984" rel="noreferrer" target="_blank"><i className="fab fa-whatsapp" /></a>
                  <a href="tel:+551139380984"><i className="fas fa-phone-alt" /></a>
                </span>
                <span>
                  <span>(11) 3938-0984</span>
                  <span>Whatsapp Business</span>
                </span>
              </div>
              <div className='email'>
                <p>
                  <i className="fa-solid fa-at" />
                  <a href="mailto:comercial@spartacusbrazil.com.br">
                    comercial@spartacusbrazil.com.br
                  </a>
                </p>
                <p>
                  <i className="fa-solid fa-at" />
                  <a href="mailto:sac@spartacubrazil.com.br">
                    sac@spartacubrazil.com.br
                  </a>
                </p>
              </div>
            </div>

            <FormContact>
              <InputText
                value={formValues.fullName || ''}
                name="fullName"
                label={t('Nome Completo')}
                isRequired
                onChange={handleChange}
              />
              <SelectText
                label={t('Assunto')}
                name="subject"
                isRequired
                value={formValues.subject || ''}
                onChange={handleChange}
                options={contactType}
              />
              <InputText
                value={formatterPhone(formValues.phoneNumber) || ''}
                name="phoneNumber"
                label={t('Telefone')}
                isRequired
                onChange={handleChange}
              />
              <InputText
                value={formValues.userEmail || ''}
                name="userEmail"
                label="E-mail"
                isRequired
                onChange={handleChange}
              />
              <TextareaText
                value={formValues.message || ''}
                name="message"
                isRequired
                label={t('Mensagem')}
                onChange={handleChange}
              />
              <button className='button' onClick={sendEmail}>{t('ENVIAR')}</button>
            </FormContact>
          </div>
{/* 
          <div className='right'>
            <h2><i className="fas fa-location-arrow" />NOSSA LOCALIZAÇÃO</h2>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d543.4285754806066!2d-46.63977464471661!3d-23.613758726175522!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce5a4f04bf7eb3%3A0x747938a25b8a2aa!2sAlameda%20dos%20Guat%C3%A1s%2C%20147%20-%20Pra%C3%A7a%20da%20%C3%81rvore%2C%20S%C3%A3o%20Paulo%20-%20SP%2C%2004053-040!5e0!3m2!1spt-BR!2sbr!4v1645497840212!5m2!1spt-BR!2sbr" allowFullScreen="" loading="lazy" />
          </div> */}
        </SectionContainer>
      </ContactContainer>
      <Footer transparencyMenu={transparencyMenu} />
    </div>
  )
}

Contact.propTypes = {
  transparencyMenu: PropTypes.bool.isRequired,
}

export default Contact
