import styled from '@emotion/styled'

export const HeaderContent = styled.header`
  width: 100%;
  left: 0;
  position: absolute;
  top: 0;
  background-color: var(--color-black-1);
  height: 80px;
  transition: all 0.3s ease-in;
  z-index: 1000;
  min-width: 320px;
  display: flex;
  justify-content: space-between;

  @media (min-width: 760px) {
    position: fixed;

    &.scrolled {
      background-color: var(--color-hover-black-dark);
      height: 40px;

      .logo {
        width: 130px;
        margin-top: 6px;
        max-height: initial;
      }
    }
  }

  .logo {
    max-height: calc(100% - 20px);
    margin-left: 10px;
    display: inline-block;
    vertical-align:top;
    transition: all 0.3s ease-in;
    width: 230px;
    margin-top: 16px;

    @media (min-width: 420px) {
      margin-top: 10px;
      width: 275px;
    }

    @media (min-width: 1280px) {
      margin-left: 30px; 
    }
  }
`

export const MenuContainer = styled.nav`
  text-align: right;
  display: flex;
  justify-content: flex-end;
  width: calc(100% - 295px);
  margin-left: 10px;
  transition: all 0.3s ease-in;

  ul {
    float: left;
    width: calc(100% - 125px);
    margin-right: 10px;
    transition:all 0.3s ease-in;

    &.no-client {
      width: 100%;
      margin-right: 0;
    }

    @media (min-width: 920px) {
      width: calc(100% - 145px);
    }

    li {
      display: inline-block;
      margin: 0 5px;

      a {
        height: 80px;
        padding: 10px;
        display: block;
        font-size: 13px;
        font-weight: 300;
        text-align: center;
        color: var(--color-light);
        transition:all 0.3s ease-in;
        
        @media (min-width: 920px) {
          padding: 10px 20px;
          letter-spacing: 1px;
        }

        i {
          font-size: 18px;
          margin: 10px 0;
        }
        
        span {
          display: block;
        }

        &.active {
          i {
            color: var(--color-primary);
          }

          box-shadow: 0 -5px var(--color-primary) inset;
        }

        &:hover {
          color: var(--color-primary);
          box-shadow: 0 -5px var(--color-primary) inset;
        }
      }
    }
  }

  button {
    float: right;
    height: 80px;
    width: 115px;
    padding: 10px;
    margin: 0px;
    color: var(--color-primary);
    background-color: var(--color-black-1);
    border: 2px solid var(--color-primary);
    transition: all 0.3s ease-in;
    overflow: hidden;

    @media (min-width: 920px) {
      width: 135px;
      padding: 10px;
    }

    &:hover {
      color: var(--color-black-1);
      background-color: var(--color-primary);
    }

    span {
      display: block;
      font-size: 13px;
      margin-top: 8px;
      font-weight: 600;
    }

    &.language-bt {
      border: 1px solid #fecc0047;
      padding: 0;

      i {
        font-size: 18px;
        margin-bottom: 10px;
      }
    }
  }

  &.mobile {
    max-width: 450px;
    background-color: var(--color-primary);
    position: absolute;
    width: 100%;
    right: 0;
    top: 100%;
    padding: 20px;
    display: block;

    ul {
      width: 100%;
      margin: 0;

      li {
        width: 100%;
        border-bottom: 1px solid var(--color-black-2);
        margin: 0;

        &:first-of-type {
          border-top: 1px solid var(--color-black-2);
        }

        a {
          color: var(--color-black-2);
          font-weight: 800;

          i{
            color: var(--color-black-2);
          }

          &.active {
            box-shadow: 0 0 0 3px var(--color-black-2) inset;
            background-color: var(--color-hover-black-light);
          }
        }
      }
    }

    button {
      width: calc(100% - 50px);
      margin: 10px 0 0;
      border: none;
      display: inline-block;

      &.language-bt {
        width: 100%;
      }
    }
  }

  @media (min-width: 760px) {
    &.scrolled {
      width: calc(100% - 150px);

      ul {
        li {
          a {
            height: 40px;

            span {
              margin-top: 5px;
            }
          }
        }
      }

      & > div {
        button {
          padding: 0;

          i {
            display: none;
          }
        }
      }

      button {
        height: 40px;
        padding-top: 10px;
        background-color: transparent;

        &:hover {
          background-color: var(--color-primary)
        }

        span {
          margin: 0;
        }
      }
    }
  }

  @media (min-width: 1280px) {
    width: calc(100% - 316px);

    &.scrolled {
      width: calc(100% - 170px);
    }
  }
`

export const StyledButtonMobile = styled.button`
  background-color: transparent;
  border: none;
  float: right;
  font-size: 21px;
  color: var(--color-primary);
  width: 80px;
  height: 80px;
  line-height: 80px;

  &:hover, &.active {
    background-color: var(--color-primary);
    color: var(--color-black-1);
  }
`
