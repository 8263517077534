import React from 'react'
import PropTypes from 'prop-types'
import { Link } from "react-scroll"
import Truck from '@/imgs/trucks.jpg'
import Agent from '@/imgs/agent.jpg'
import { useTranslation, Trans } from 'react-i18next'
import JuridalSupport from '@/imgs/juridicalSupport.jpg'
import useTranslatedArmedEscort from '@/TextsPagesMock/armedEscort'
import HeaderArticle from '@/components/fragments/HeaderArticle'
import Footer from '@/components/Footer'
import {
  StyledContainer,
  StyledBreadcrumb,
  StyledLogistic,
  SectionContainer,
  ArmedEscort,
  ArmedEscortManagement,
  RouteInspector,
  InteligenceRegulation,
  JuridicalSupport,
} from './styles'

const Services = (props) => {
  const { isMobile, transparencyMenu } = props
  const { t } = useTranslation()
  const armedEscortList = useTranslatedArmedEscort()

  const renderArmedEscortList = (row, index) => (
    <div className='tr' key={index}>
      <div>
        {isMobile && (<span>{t('Benefícios')}:</span>)}
        {row.benefits}
      </div>
      <div>
        {isMobile && (<span>{t('Contratante')}:</span>)}
        {row.contractor}
      </div>
      <div>
        {isMobile && (<span>{t('Escolta Armada')}:</span>)}
        {row.armedEscort}
      </div>
    </div>
  )

  return (
    <StyledContainer className='has-header'>
      <HeaderArticle>
        <i className="fas fa-shield" />
        <Trans i18nKey="SERVICOS_ATIVOS" components={{ 1: <span /> }} />
      </HeaderArticle>
  
      <StyledBreadcrumb>
        <li>
          <Link to="logistic">
            <i className="fas fa-angle-right" />
            {t('ACOMPANHAMENTO LOGÍSTICO')}
          </Link>
        </li>
        <li>
          <Link to="armed-escort">
            <i className="fas fa-angle-right" />
            {t('ESCOLTA ARMADA CARACTERIZADA')}
          </Link>
        </li>
        <li>
          <Link to="armed-escort-menagement">
            <i className="fas fa-angle-right" />
            {t('PRONTA RESPOSTA E RECUPERAÇÃO DE BENS')}
          </Link>
        </li>
        <li>
          <Link to="route-inspector">
            <i className="fas fa-angle-right" />
            {t('AUDITOR E FISCAL DE ROTA')}
          </Link>
        </li>
        <li>
          <Link to="inteligence-regulation">
            <i className="fas fa-angle-right" />
            {t('INTELIGÊNCIA')}
          </Link>
        </li>
        <li>
          <Link to="juridical-support">
            <i className="fas fa-angle-right" />
            {t('APOIO JURÍDICO')}
          </Link>
        </li>
      </StyledBreadcrumb>
  
      <StyledLogistic
        id="logistic"
        style={{backgroundImage: `url(${Truck})`}}
      >
        <SectionContainer>
          <h2>
            <i className="fas fa-road" />
            {t('Acompanhamento Logístico')}
          </h2>
          <div>
            <Trans i18nKey="ACOMPANHAMENTO_LOGISTICO_TEXT" components={{ p: <p />, 1: <b /> }} />
          </div>
  
          <ul>
            <Trans i18nKey="ACOMPANHAMENTO_LOGISTICO_LI" components={{ li: <li /> }} />
          </ul>
          <span className="clear" />
        </SectionContainer>
      </StyledLogistic>

      <ArmedEscort id="armed-escort">
        <SectionContainer>
          <h2>
            <i className="fas fa-volume-off spinoff" />
            <i className="fas fa-car-side" />
            {t('Escolta Armada Caracterizada')}
          </h2>
    
          <h4>
            <i className="fas fa-globe-americas" />
            {t('beneficios')}
          </h4>
          <div className='table'>
            {!isMobile && (
              <div className='th'>
                <div>{t('Benefícios')}</div>
                <div>{t('Contratante')}</div>
                <div>{t('Escolta Armada')}</div>
              </div>
            )}
            {armedEscortList.map((row, index) => renderArmedEscortList(row, index))}
          </div>

          <div className='left-side'>
            <h3>{t('Empresas devidamente homologadas e regularizadas nos órgãos pertinentes em todo território nacional')}.</h3>
            <ul>
              <li>{t('Equipe de Agentes Treinados')}</li>
              <li>{t('Agente Uniformizado')}</li>
              <li>{t('Viatura Caracterizada')}</li>
              <li>{t('Tecnologia de Rastreamento Embarcada')}</li>
              <li>{t('Armamento Regular')}</li>
            </ul>
          </div>
          <iframe src="https://www.google.com/maps/d/embed?mid=1UHpCwZk8NatkO1meHrNNkwjvCWf1i_HU&ehbc=2E312F" />
        </SectionContainer>
  
      </ArmedEscort>

      <ArmedEscortManagement id="armed-escort-menagement">
        <SectionContainer>
          <h2 className="left">
            <i className="fas fa-user-shield" />
           {t('Gestão de Escolta Armada')}
          </h2>
          <img src={Agent} alt="Logo Agente Segurança" />
          <p>{t('Gestão de Escolta Armada texto')}</p>

          <h4><i className="fas fa-asterisk" />{t('Embarcador e Distribuidor')}</h4>
          <p>{t('Gestão de Escolta Armada texto 2')}</p>
          <h4><i className="fas fa-asterisk" />{t('Transportador e Operador Logístico')}</h4>
          <p>{t('Gestão de Escolta Armada texto 3')}</p>
          <h4><i className="fas fa-asterisk" />{t('Empresas De Segurança')}</h4>
          <p>{t('Gestão de Escolta Armada texto 4')}</p>
          <h4><i className="fas fa-asterisk" />{t('Gerenciadora De Risco E Centrais De Monitoramento')}</h4>
          <p>{t('Gestão de Escolta Armada texto 5')}</p>
          <span className='clear' />
        </SectionContainer>
      </ArmedEscortManagement>

      <RouteInspector id="route-inspector">
        <SectionContainer>
          <h2>
            <i className="fas fa-user-ninja" />
            {t('Pronta Resposta, Auditor ou Fiscal de Rota')}
          </h2>
          <iframe src="https://www.google.com/maps/d/embed?mid=1WjFkIoKALdWNuwzJDq4iqdYbPquO41_D&ehbc=2E312F" />
          <div className='right-side'>
            <Trans i18nKey="PRONTA_RESPOSTA_1" components={{ p: <p /> }} />
            <div className='destak'>
              <Trans i18nKey="PRONTA_RESPOSTA_2" components={{ p: <p />, b: <b /> }} />
              <ul>
                <Trans i18nKey="PRONTA_RESPOSTA_LI" components={{ li: <li /> }} />
              </ul>
              <Trans i18nKey="PRONTA_RESPOSTA_3" components={{ p: <p />, b: <b /> }} />
            </div>

            <p>{t('PRONTA_RESPOSTA_4')}</p>
          </div>
        </SectionContainer>
      </RouteInspector>

      <InteligenceRegulation id="inteligence-regulation">
        <SectionContainer className='support'>
          <h2>
            <i className="fas fa-user-secret" />
            {t('Inteligência, Sindicância ou Regulação')}
          </h2>
          <div className='principal'>{t('EVENTO')}</div>
          <div className='first'>
            <Trans i18nKey="INTELIGENCIA_1" components={{ h4: <h4 />, h6: < h6/>, i: < i/>, p: <p /> }} />
          </div>
          <div className='second'>
            <Trans i18nKey="INTELIGENCIA_2" components={{ h4: <h4 />, h6: < h6/>, p: <p /> }} />
          </div>
          <div className='third'>
            <Trans i18nKey="INTELIGENCIA_3" components={{ h4: <h4 />, h6: < h6/>, ul: <ul />, li: <li /> }} />
          </div>
          <span className='clear' />
        </SectionContainer>
      </InteligenceRegulation>

      <JuridicalSupport
        id="juridical-support"
        style={{backgroundImage: `url(${JuridalSupport})`}}
      >
        <SectionContainer>
          <h2>
            <i className="fas fa-balance-scale" />
            {t('Apoio Jurídico Rota')}
          </h2>
          <Trans i18nKey="JURIDICO" components={{ p: <p />, ul: <ul />, li: <li /> }} />
        </SectionContainer>
      </JuridicalSupport>
      <Footer transparencyMenu={transparencyMenu} />
    </StyledContainer>
  )
}

Services.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  transparencyMenu: PropTypes.bool.isRequired,
}

export default Services
