import styled from '@emotion/styled'

export const ContainerLanguageSelector = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  margin: 0 5px;

  &.mobile {
    display: inline-block;
    margin: 0 10px 0 0; 
  }
`
