import { useTranslation } from 'react-i18next';

const useTranslatedServiceList = () => {
  const { t } = useTranslation();

  return [
    {
      icon: 'fas fa-road',
      title: t('Acompanhamento Logístico'),
      text: t('Acompanhamento Logístico text'),
      url: '/servicos#logistic'
    },
    // {
    //   icon: 'fas fa-satellite',
    //   title: t('Rastreamento'),
    //   text: t('Rastreamento text'),
    //   url: '/servicos#tracking'
    // },
    {
      icon: ['fas fa-volume-off spinoff', 'fas fa-car-side'],
      isPolice: true,
      title: t('Escolta Armada Caracterizada'),
      text: t('Escolta Armada Caracterizada text'),
      url: '/servicos#armed-escort'
    },
    {
      icon: 'fas fa-user-shield',
      title: t('Gestão de Escolta Armada'),
      text: t('Gestão de Escolta Armada text'),
      url: '/servicos#armed-escort-menagement'
    },
    {
      icon: 'fas fa-user-ninja',
      title: t('Pronta Resposta, Auditor ou Fiscal de Rota'),
      text: t('Pronta Resposta, Auditor ou Fiscal de Rota text'),
      url: '/servicos#route-inspector'
    },
    {
      icon: 'fas fa-user-secret',
      title: t('Inteligência, Sindicância ou Regulação'),
      text: t('Inteligência, Sindicância ou Regulação text'),
      url: '/servicos#inteligence-regulation'
    },
    {
      icon: 'fas fa-balance-scale',
      title: t('Apoio Jurídico Rota'),
      text: t('Apoio Jurídico Rota text'),
      url: '/servicos#juridical-support'
    },
  ];
};

export default useTranslatedServiceList;
