import { useTranslation } from 'react-i18next';

const useTranslatedArmedEscort = () => {
  const { t } = useTranslation()

  return [
    {
      benefits: t('Visibilidade Nacional'),
      contractor: t('Canal Único Nacional para Acionamento'),
      armedEscort: t('Exposição da Empresa no Mercado'),
    }, {
      benefits: t('Pré analise documental'),
      contractor: t('Segurança na Contratação de Fornecedor'),
      armedEscort: t('Do Contratante'),
    }, {
      benefits: t('Validação de Certificações'),
      contractor: t('Fornecedor devidamente homologado'),
      armedEscort: t('Cliente consolidado no mercado'),
    }, {
      benefits: t('Software de Gestão'),
      contractor: t('Para Acionar a Rede Nacional'),
      armedEscort: t('Para ser acionado pelos Contratantes'),
    }, {
      benefits: t('Controle Operacional de Missão'),
      contractor: t('Acompanhamento de status da Missão'),
      armedEscort: t('Adequação ao uso de equipe e VTR'),
    }, {
      benefits: t('Controle Financeiro'),
      contractor: t('Fechamento missão a missão com exatidão'),
      armedEscort: t('Fechamento missão a missão com exatidão'),
    }, {
      benefits: t('Relatório de Missões'),
      contractor: t('Contratadas'),
      armedEscort: t('Prestadas'),
    }, {
      benefits: t('Segurança Juridica'),
      contractor: t('Controle e Frequencia dos Agentes'),
      armedEscort: t('Controle e Frequencia dos Agentes'),
    }, {
      benefits: t('Consultoria'),
      contractor: t('Trabalhista'),
      armedEscort: t('Trabalhista'),
    }, {
      benefits: t('Suporte'),
      contractor: t('Técnico Operacional e Jurídico'),
      armedEscort: t('Técnico Operacional e Jurídico'),
    }
  ]
}

export default useTranslatedArmedEscort;


