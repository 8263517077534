import styled from '@emotion/styled'

export const StyledContainer = styled.section`
  background-color: var(--color-black-2);
  height: 100%;

  @keyframes Title {
    0% { opacity: 0; left: -100% }
    10% { opacity: 1; left: 0; }
    90% { opacity: 1; left: 0; }
    100% { opacity: 0; left: 100%;}
  }
`

export const StyledLogo = styled.article`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  height: 100%;

  div {
    max-width: 550px;
    text-align: center;
    overflow: hidden;

    img {
      max-width: 350px;
      width: 100%;
    }
  }

  h2 {
    color: var(--color-primary);
    margin-top: 30px;
    font-size: 22px;
    text-transform: uppercase;
    font-weight: 300;
    padding: 20px 10px 10px;
    position: relative;
    border-top: 2px solid;
    box-shadow: 0 -1px var(--color-gray-ultra-light);
    animation: Title linear 7s infinite;
  }

  h3 {
    color: var(--color-gray-light);
    font-size: 16px;
    text-transform: uppercase;
    position: relative;
    font-weight: 600;
    animation: Title linear 7s infinite;
  }

  @media (min-width: 760px) {
    h2 {
      font-size: 40px;
      padding: 20px 20px 10px;
    }

    h3 {
      font-size: 24px;
    }
  }
`

export const StyledBannerWhoWeAre = styled.article`
  width: 100%;
  background-color: var(--color-black-7);
  border-top: 3px solid var(--color-primary);
  border-bottom: 3px solid var(--color-primary);
  padding: 30px 20px;
  text-align: center;

  h3 {
    color: var(--color-primary);
    position: relative;
    line-height: 30px;
    font-weight: 300;
    letter-spacing: 2;
  }

  .button {
    color: var(--color-black-6);
    margin: 20px auto 0;
    font-weight: 600;
  }

  @media (min-width: 760px) {
    display: flex;
    justify-content: center;
    align-items: center;

    h3 {
      font-size: 26px;
    }

    .button {
      margin: 0 0 0 50px;
      font-size: 18px;
      width: 150px;
    }
  }

  @media (min-width: 1280px) {
    padding: 150px 20px;
  }
`

export const ServiceSection = styled.article`
  h2 {
    text-align: center;
    padding: 40px;
    text-transform: uppercase;
    font-size: 30px;
    background-color: var(--color-primary);
    margin-bottom: 10px;
    color: var(--color-black-5);

    img {
      display: block;
      width: 100px;
      margin: 20px auto 0;
    }
  }

  .containerBoxes {
    padding: 15px;
    justify-content: center;
  }

  @media (min-width: 760px) {
    h2 {
      font-size: 40px;
      position: relative;
      overflow: hidden;
    }

    .containerBoxes {
      display: grid;
      grid-template-columns: repeat(2, calc(50% - 10px));
      grid-gap: 20px;
    }
  }

  @media (min-width: 1024px) {
    background-image: url('/BgHome.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom right;

    .containerBoxes {
      grid-template-columns: repeat(3, calc(33.3333333% - 13.333333px));
    }
  }

  @media (min-width: 1280px) {
    .containerBoxes {
      padding: 50px;
      grid-template-columns: repeat(4, calc(25% - 37.5px));
      grid-gap: 50px;
    }
  }

  @media (min-width: 1480px) {
    .containerBoxes {
      padding: 100px 150px;
    }
  }
`

export const StyledBoxes = styled.div`
  border: 1px solid var(--color-gray-light);
  padding: 20px 15px 50px;
  margin-bottom: 15px;
  text-align: center;
  display: block;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 26px;
  position: relative;

  p {
    text-transform: initial;
    font-size: 16px;
    font-weight. inherit;
    line-height: 17px;
    margin-top: 20px;
    border-top: 1px solid var(--color-gray-light);
    padding-top: 20px;
  }

  div {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    border: 7px solid var(--color-primary);
    margin: 0 auto 20px;
    position: relative;

    i {
      line-height: 85px;
      font-size: 50px;

      &.spinoff {
        font-size: 18px;
        position: absolute;
        transform: rotate(90deg);
        top: -23px;
        left: 36px;
      }
    }
  }

  .button {
    margin: 0;
    position: absolute;
    bottom: 0;
    right: 0;
    color: var(--color-light);

    &:hover {
      background-color: var(--color-black-5);
      color: var(--color-primary);
    }
  }

  @media (min-width: 760px) {
    margin: 0;
  }
`

export const ClientSection = styled.article`
  h2 {
    text-align: center;
    padding: 40px;
    text-transform: uppercase;
    font-size: 30px;
    background-color: var(--color-black-5);
    color: var(--color-primary);

    img {
      display: block;
      width: 100px;
      margin: 20px auto 0;
    }
  }

  div {
    &.content {
    
      @media (min-width: 580px) {
        padding: 10px;

        & > div {
          &.container-boxes {
            display: grid;
            grid-template-columns: 100%;
            grid-gap: 50px;
                  
            .group {
              border: 2px solid var(--color-black-3);
            }
          }
        }
      }

      @media (min-width: 760px) {
        padding: 50px;
      }

      @media (min-width: 1420px) {
        & > div {
          &.container-boxes {
            grid-template-columns: repeat(2, calc(50% - 25px));

            .group-4 {
              grid-column-start: 1;
              grid-column-end: 3;
            }
          }
        }
      }
    }
  }

  h3 {
    border-bottom: 2px solid var(--color-black-3);
    text-align: center;
    padding: 20px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 2px;
    background-color: var(--color-primary);
    color: var(--color-black-3);

    i {
      margin-right: 10px; 
    }

    @media (max-width: 580px) {
      color: var(--color-primary);
      background-color: var(--color-black-8);
    }
  }
`

export const ContainerBoxes = styled.div`
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 5px));
  grid-gap: 10px;
  padding: 10px;

  .boxes {
    border: 1px solid var(--color-gray-light);

    h4 {
      background-color: var(--color-gray-ultra-light);
      color: var(--color-black-3);
      border-bottom: 1px solid var(--color-gray-light);
      text-align: center;
      text-transform: uppercase;
      font-weight: 700;
      padding: 10px;
      font-size: 14px;
    }

    &.white {
      background-color: var(--color-hover-black-dark)
    }

    .img {
      padding: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 150px;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  @media (min-width: 760px) {
    grid-template-columns: repeat(3, calc(33.333333% - 13.33333px));
    grid-gap: 20px;
    padding: 20px;
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(4, calc(25% - 15px));
  }

  @media (min-width: 1280px) {
    grid-template-columns: repeat(5, calc(20% - 16px));
  }

  @media (min-width: 1420px) {
    grid-template-columns: repeat(4, calc(25% - 15px));

    &.items-4 {
      grid-template-columns: repeat(8, calc(12.5% - 17.5px));
    }

    .boxes {
      .img {
        height: 115px;
      }
    }
  }
`
